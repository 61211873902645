@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .scrollbar::-webkit-scrollbar {
    width: 15px;
    height: 15px;
  }

  .scrollbar::-webkit-scrollbar-track {
    border-radius: 100vh;
    background: black;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background: #00f3cd;
    border-radius: 100vh;
    border: 3px solid #00f3cd;
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: black;
  }
}

@font-face {
  font-family: RobotoConLight;
  src: url('//public/fonts/RobotoCondensed-Light.woff') format('woff');
}

.robotofont {

}

.vjs-poster img {
  width: 100%;
  /* height: 100dvh !important; */
  -o-object-fit: cover;
  object-fit: cover !important;
}

.vjs-tech {
  width: 100%;
  /* height: 100dvh !important; */
  -o-object-fit: cover;
  object-fit: cover !important;
}

/* swiper section */

.swiper {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}

.swiper-slide,
.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 10% !important;
  height: 300px;
}

.swiper-slide img {
  display: block;
  width: 100%;
}

/* .mySwiper {
  min-height: 15,5rem;
} */

.swiper-pagination-bullet-active {
  background: #00f3cd !important;
}

.sample-slider .swiper-pagination-bullets{
 
  position: relative;


 
}

.sample-slider .swiper-wrapper{
  width: 150%;
}


.sample-slider2 .swiper-pagination-bullets{
 
  position: relative;
  top:-183vw !important;


 
}